import React from "react";
import { Routes, Route } from "react-router";
import Home from "../pages/home/home";
import Mobilemenu from "../pages/mobilemenu/mobilemenu";
import Nosotros from "../pages/nosotros/nosotros";
import Equipo from "../pages/equipo/equipo";
import Areasdepractica from "../pages/areasdepractica/areasdepractica";
import Actualidad from "../pages/actualidad/actualidad";
import Contacto from "../pages/contacto/contacto";
import Agendatucapactitacion from "../pages/agendatucapactitacion/agendatucapactitacion";
import Politicadetratamientodedatos from "../pages/politicadetratamientodedatos/politicadetratamientodedatos";
import Derechodelosnegocios from "../pages/derechodelosnegocios/derechodelosnegocios";
import Derecholaboral from "../pages/derecholaboral/derecholaboral";
import Derechomigratorio from "../pages/derechomigratorio/derechomigratorio";
import Seguridadysalud from "../pages/seguridadysalud/seguridadysalud";
import Derechotributario from "../pages/derechotributario/derechotributario";
import Juanuribe from "../pages/juanuribe/juanuribe";
import Diegoduran from "../pages/diegoduran/diegoduran";
import Mariapaulasuarez from "../pages/mariapaulasuarez/mariapaulasuarez";
import Hernanpanesso from "../pages/hernanpanesso/hernanpanesso";
import Williambolivar from "../pages/williambolivar/williambolivar";
import Victorcabrera from "../pages/victorcabrera/victorcabrera";

function RoutingApp() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Home" element={<Home />} />
      <Route path="/Mobilemenu" element={<Mobilemenu />} />
      <Route path="/Nosotros" element={<Nosotros />} />
      <Route path="/Equipo" element={<Equipo />} />
      <Route path="/Areasdepractica" element={<Areasdepractica />} />
      <Route path="/Actualidad" element={<Actualidad />} />
      <Route path="/Contacto" element={<Contacto />} />
      <Route
        path="/Agendatucapactitacion"
        element={<Agendatucapactitacion />}
      />
      <Route
        path="/Politicadetratamientodedatos"
        element={<Politicadetratamientodedatos />}
      />
      <Route path="/Derechodelosnegocios" element={<Derechodelosnegocios />} />
      <Route path="/Derecholaboral" element={<Derecholaboral />} />
      <Route path="/Derechomigratorio" element={<Derechomigratorio />} />
      <Route path="/Seguridadysalud" element={<Seguridadysalud />} />
      <Route path="/Derechotributario" element={<Derechotributario />} />
      <Route path="/Juanuribe" element={<Juanuribe />} />
      <Route path="/Diegoduran" element={<Diegoduran />} />
      <Route path="/Mariapaulasuarez" element={<Mariapaulasuarez />} />
      <Route path="/Hernanpanesso" element={<Hernanpanesso />} />
      <Route path="/Williambolivar" element={<Williambolivar />} />
      <Route path="/Victorcabrera" element={<Victorcabrera />} />
    </Routes>
  );
}

export default RoutingApp;
