import React from "react";
import { Link } from "react-router-dom";

import styles from "./equipo.module.css";

/**
 * Equipo component.
 * @component
 * @type {React.FC}
 *
 * @returns {React.ReactElement} The Equipo component.
 */
const Equipo = () => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  function aguaRender() {
    return (
      <main className={styles.Equipo}>
        <header className={styles.equipoheader}>
          <Link to="/Home">
            <section
              className={styles["layer-130-e88bd466c4a64733aacf1b663338bf80"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-238-c5593d147b4e415096b98f570041c122"]
                  }
                  alt="imageview-238"
                  src="/assets/bavieraLogo.png"
                />
              </picture>
            </section>
          </Link>
          <section
            className={styles["layer-131-df0931a01d5147da833f2f82d2e281a8"]}
          >
            <Link to="/Nosotros">
              <p
                className={styles["text-124-7ff37d8633a141e4b59cbd2aa11b1360"]}
              >
                Nosotros
              </p>
            </Link>
            <Link to="/Equipo">
              <p
                className={styles["text-125-2f9a85278af1463882fcf6efab937b4a"]}
              >
                Equipo
              </p>
            </Link>
            <Link to="/Areasdepractica">
              <p
                className={styles["text-126-c87a96e5263e41ae8b5621bda905da34"]}
              >
                Áreas de Práctica
              </p>
            </Link>
            <Link to="/Contacto">
              <p
                className={styles["text-127-ff5a492558244a8eae6bf5b22e9257b5"]}
              >
                Contacto
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-132-44dc677b353e4c91888a8f186ad3f6f6"]}
          >
            <Link to="/Agendatucapactitacion">
              <p
                className={styles["text-128-6b4f740993e14878a0e7fe5376fa8e7a"]}
              >
                Agenda tu Capacitación
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-133-697d9d9bb40b46ada5838c25256f3581"]}
          >
            <Link to="/Mobilemenu">
              <span
                className={styles["icon-6-9b006cc311a447189c89ff552bb8c285"]}
              >
                density_medium
              </span>
            </Link>
          </section>
        </header>
        <section className={styles.equiposection1}>
          <section
            className={`${styles["layer-119-7bc330e88c2341b88b3352beac7356a2-orientation"]} ${styles["layer-119-7bc330e88c2341b88b3352beac7356a2"]}`}
          >
            <section
              className={styles["layer-121-cd604044518a4a50814d4eab570e7199"]}
            >
              <Link to="/Juanuribe">
                <section
                  className={
                    styles["layer-613-97ddd4409ceb41e993a0811e30df1e67"]
                  }
                >
                  <img
                    className={
                      styles["imageview-57-6f148ea86d024bf998c0f0b26a7322d7"]
                    }
                    alt="imageview-57"
                    src="/assets/Juan Uribe - Square.png"
                  />
                </section>
              </Link>
              <Link to="/Juanuribe">
                <p
                  className={
                    styles["text-108-7349b2207bd849558a7815d17bb9a99c"]
                  }
                >
                  Juan Uribe
                </p>
              </Link>
              <Link to="/Juanuribe">
                <p
                  className={
                    styles["text-109-4d9491eb9323491e802e42f0d8776423"]
                  }
                >
                  Laboral y Migratorio
                </p>
              </Link>
              <picture>
                <img
                  className={
                    styles["imageview-183-5f50fe5a5a544ec09dcb406ba063ee01"]
                  }
                  alt="imageview-183"
                  src="/assets/linkedin-Black.png"
                />
              </picture>
            </section>
            <section
              className={styles["layer-122-c86f5fa73da74a9c9d3a1f2079a97a94"]}
            >
              <Link to="/Diegoduran">
                <section
                  className={
                    styles["layer-614-bd467e738bb74b579d658132a2800269"]
                  }
                >
                  <img
                    className={
                      styles["imageview-59-bdfedbb077ef48ab8e556bc6ea004fcb"]
                    }
                    alt="imageview-59"
                    src="/assets/Diego Felipe Duran - Square.png"
                  />
                </section>
              </Link>
              <Link to="/Diegoduran">
                <p
                  className={
                    styles["text-110-29d426a2a21a462d845c9c9388f0e96b"]
                  }
                >
                  Diego Felipe Durán
                </p>
              </Link>
              <Link to="/Diegoduran">
                <p
                  className={
                    styles["text-111-5e7c0ea1371d438199ec3fc9ce10477c"]
                  }
                >
                  Laboral y Migratorio{" "}
                </p>
              </Link>
              <picture>
                <img
                  className={
                    styles["imageview-60-205d069735ac44cf8853a6d3ae8e5b44"]
                  }
                  alt="imageview-60"
                  src="/assets/linkedin-Black.png"
                  onClick={() =>
                    openInNewTab(
                      "https://www.linkedin.com/in/diego-felipe-dur%C3%A1n-t%C3%A9llez-2a4766148/",
                    )
                  }
                />
              </picture>
            </section>
            <section
              className={styles["layer-124-dfa630de779540679c45118eb31e1a07"]}
            >
              <Link to="/Mariapaulasuarez">
                <section
                  className={
                    styles["layer-615-afa9e4ba2403402a82f4343e7cfb2823"]
                  }
                >
                  <img
                    className={
                      styles["imageview-63-2e0611094b95479ab118519f321a4cb0"]
                    }
                    alt="imageview-63"
                    src="/assets/Maria Paula Suarez - Square.png"
                  />
                </section>
              </Link>
              <Link to="/Mariapaulasuarez">
                <p
                  className={
                    styles["text-114-60a5249ad3ea4d4798f1d4d62a5ab129"]
                  }
                >
                  María Paula Suárez
                </p>
              </Link>
              <Link to="/Mariapaulasuarez">
                <p
                  className={
                    styles["text-115-10a29eb9410d48f0952633e3651cdf92"]
                  }
                >
                  Laboral y Migratorio
                </p>
              </Link>
              <picture>
                <img
                  className={
                    styles["imageview-64-201a5b2a8b9c4a3394adbe33a85bf7b6"]
                  }
                  alt="imageview-64"
                  src="/assets/linkedin-Black.png"
                  onClick={() =>
                    openInNewTab(
                      "https://www.linkedin.com/in/mar%C3%ADa-paula-su%C3%A1rez-isaza-58449a15b/",
                    )
                  }
                />
              </picture>
            </section>
          </section>
          <section
            className={`${styles["layer-120-686902dff360450ea740c2e4277b068b-orientation"]} ${styles["layer-120-686902dff360450ea740c2e4277b068b"]}`}
          >
            <section
              className={styles["layer-125-d31b0b64c59f4e3fbed5c1d60797cbf7"]}
            >
              <Link to="/Williambolivar">
                <section
                  className={
                    styles["layer-617-390b98faf31f41fdaffddc26b3416c44"]
                  }
                >
                  <img
                    className={
                      styles["imageview-65-504f17405fd4460ca6ddd02269cf7149"]
                    }
                    alt="imageview-65"
                    src="/assets/William Bolivar - Square.png"
                  />
                </section>
              </Link>
              <Link to="/Williambolivar">
                <p
                  className={
                    styles["text-116-f1b00d4a725a4bd0854526d804f44a28"]
                  }
                >
                  William Bolívar
                </p>
              </Link>
              <Link to="/Williambolivar">
                <p
                  className={
                    styles["text-117-2704d262e1f34476abbccf22ebca6910"]
                  }
                >
                  Laboral y Tributario
                </p>
              </Link>
              <picture>
                <img
                  className={
                    styles["imageview-66-64bc6664425c4f36a1ab77cf5a5c3f4a"]
                  }
                  alt="imageview-66"
                  src="/assets/linkedin-Black.png"
                  onClick={() =>
                    openInNewTab(
                      "https://www.linkedin.com/in/william-bolivar-760a3b126/",
                    )
                  }
                />
              </picture>
            </section>
            <section
              className={styles["layer-126-b949a8af2d26485b83198f98045011a0"]}
            >
              <Link to="/Hernanpanesso">
                <section
                  className={
                    styles["layer-618-f7332bd51d514f288ac797124953192c"]
                  }
                >
                  <img
                    className={
                      styles["imageview-67-20f417fc569d4aa297bf8ea9eb1b54ad"]
                    }
                    alt="imageview-67"
                    src="/assets/Hernan Panesso - Square.png"
                  />
                </section>
              </Link>
              <Link to="/Hernanpanesso">
                <p
                  className={
                    styles["text-118-25a55263ea1b441ea5e3687a3ee0e745"]
                  }
                >
                  Hernán Panesso
                </p>
              </Link>
              <Link to="/Hernanpanesso">
                <p
                  className={
                    styles["text-119-ef5e4b1f28ac4b438e79df59c2877ad5"]
                  }
                >
                  Competencia y Datos Personales
                </p>
              </Link>
              <picture>
                <img
                  className={
                    styles["imageview-68-959e427782be489e8e46a9843581e00e"]
                  }
                  alt="imageview-68"
                  src="/assets/linkedin-Black.png"
                  onClick={() =>
                    openInNewTab(
                      "https://www.linkedin.com/in/hern%C3%A1n-antonio-panesso-mercado-20376a15/",
                    )
                  }
                />
              </picture>
            </section>
            <section
              className={styles["layer-127-9da32d77ccef448bbe96945f508b799d"]}
            >
              <Link to="/Victorcabrera">
                <section
                  className={
                    styles["layer-619-295c4dbf786b4c589e3759d1344cac70"]
                  }
                >
                  <img
                    className={
                      styles["imageview-69-68fff1e56cb04bbf88dfe9f3cb642561"]
                    }
                    alt="imageview-69"
                    src="/assets/Victor Cabrera - Square.png"
                  />
                </section>
              </Link>
              <Link to="/Victorcabrera">
                <p
                  className={
                    styles["text-120-5f047a0f154e453992fe275d0c8bf839"]
                  }
                >
                  Victor Cabrera
                </p>
              </Link>
              <Link to="/Victorcabrera">
                <p
                  className={
                    styles["text-121-d20b53dc6b2a4722bdf6b7440a2a1eba"]
                  }
                >
                  Laboral y Migratorio
                </p>
              </Link>
              <picture>
                <img
                  className={
                    styles["imageview-70-a6a7d6b4f6984b91a51d8978cd6cfa02"]
                  }
                  alt="imageview-70"
                  src="/assets/linkedin-Black.png"
                  onClick={() =>
                    openInNewTab(
                      "https://www.linkedin.com/in/victor-manuel-cabrera-arteaga/",
                    )
                  }
                />
              </picture>
            </section>
          </section>
        </section>
        <footer className={styles.equipofooter}>
          <section
            className={styles["layer-500-8f3575fe37ad486486476de31da2fb0f"]}
          >
            <section
              className={styles["layer-128-c6697fd6d2c44327a96e8bb3a45e11a9"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-71-21ae404e7dc9498193fe1a6eca7b3fda"]
                  }
                  alt="imageview-71"
                  src="/assets/linkedin-Black.png"
                  onClick={() =>
                    openInNewTab(
                      "https://www.linkedin.com/company/baviera-legal/",
                    )
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-72-f81a1dc4ce5f43d3bcd7038fb8b62db4"]
                  }
                  alt="imageview-72"
                  src="/assets/instagram.png"
                  onClick={() =>
                    openInNewTab("https://www.instagram.com/bavieralegal/")
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-73-d6b829ffb04b4f528e8dec9626df5839"]
                  }
                  alt="imageview-73"
                  src="/assets/email.png"
                  onClick={() => openInNewTab("mailto:info@bavieralegal.com")}
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-74-a42e629dbc7e4c7daa62f1af292425c1"]
                  }
                  alt="imageview-74"
                  src="/assets/llamada-telefonica.png"
                  onClick={() => openInNewTab("https://wa.me/573107749255")}
                />
              </picture>
            </section>
            <section
              className={styles["layer-129-57922db7ecfe4b1994e8884c0f9297cf"]}
            >
              <Link to="/Politicadetratamientodedatos">
                <p
                  className={
                    styles["text-122-6fee9b6acd1d4a3a9a4c62b941344745"]
                  }
                >
                  Política de tratamiento de datos
                </p>
              </Link>
              <p
                className={styles["text-123-a086dfb462094a5499f8e5913f53817c"]}
              >
                Copyrights 2023 © Baviera Legal
              </p>
            </section>
          </section>
        </footer>
      </main>
    );
  }

  return aguaRender();
};

export default Equipo;
